<template>
  <div
    class="flex flex-col p-4 sm:py-8 sm:px-16 text-white  bg-darken-green w-full"
  >
    <div class="flex flex-col sm:flex-row items-center h-full gap-12">
      <div class="flex flex-col sm:flex-row items-center gap-4">
        <div class="flex flex-col p-1">
          <mdicon name="help-circle-outline" />
        </div>
        <div class="flex flex-col items-center sm:items-start">
          <h1 class="font-semibold text-base">Contacto a nuestro soporte</h1>
          <h1>Rentas Dhelos Admin</h1>
        </div>
      </div>

      <div class="hidden sm:flex bg-white h-full w-0.5 rounded-full"></div>

      <div class="flex flex-col pb-10 sm:pb-0 gap-4">
        <div class="flex flex-row items-center gap-4">
          <mdicon name="cellphone" />
          <h1>+569 9829 4524</h1>
        </div>
        <div class="flex flex-row items-center gap-4">
          <mdicon name="email-outline" />
          <h1>rentas@dhelos.cl</h1>
        </div>
      </div>
    </div>
  </div>
</template>
