
export class MonthlyInfoRequestData {
  constructor(
    public userId: number,
    public startDate: string | undefined = undefined,
    public endDate: string | undefined = undefined
  ) {}
}

export class MonthlyInfoBalanceData {
  constructor(
    public userId: number,
    public shareGroup: string,
    public startDate: string | undefined = undefined,
    public endDate: string | undefined = undefined
  ) {}
}

export class MonthlyFullInfo {
  constructor(
    public data: MonthlyInfo[] | null,
    //public sumNominalProfitability: number | undefined,
    public sumTotalProfitability: number | undefined,
    public sumRealProfitability: number | undefined,
    public sumTotalClp: number | undefined,
  ) {}
}

export class MonthlyInfo {
  constructor(
    public month: string,
    public year: number,
    public totalClp: number
  ) {}
}
export class anualInfo {
  constructor(

    public year: number,
    public totalClp: number
  ) {}
}

export class MonthlyInfoBalance {
  constructor(
    public createdAt: Date,
    public month: string,
    public year: number,
    public totalShares: number,
    public balanceTotal: number,
    public sharesGroupB: number |null,
    public ufValue:string 
  ) {}
}

export enum FilterType {
  ByYear,
  ByConsolidated,
}