import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse, keysToSnake } from "../utils";
import { MonthlyInfoBalanceData, MonthlyInfoRequestData } from "./types";
import { getEnvConfig } from '@/configs/UrlConfig';



export class ChartService extends AuthenticatedService{
    async getBalance(user_id:number,requestData : MonthlyInfoBalanceData, typeGrafico = 'period'){
        const url = `${getEnvConfig().apiURL}/balances/all-user?user_id=${user_id}&only_b=1&type=${typeGrafico}`;
        return getResponse(
            url,
            this.token,
            "POST",
            keysToSnake(requestData), 
            undefined
        );
        
    }
    

    async getDividend(account_id:number , requestData : MonthlyInfoRequestData, typeGrafico = 'period'){
        
        const url = `${getEnvConfig().apiURL}/dividends/monthly-info?shareholder_id=${account_id}&type=${typeGrafico}`;

        return getResponse(
            url,
            this.token,
            "POST",
            keysToSnake(requestData), 
            undefined
        );
    }
    async getAllDividend(userId : number){
        const url = `${getEnvConfig().apiURL}/dividends/all-user`;

        return getResponse(
            url,
            this.token,
            "POST",
            keysToSnake({userId}), 
            undefined
        );
    }
}