import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import {
  getResponse,
  fetchDownloadFile,
  getResponsePagination,
} from "../utils";
import { Response, ResponsePagination } from "../types";
import { ShareholderMovement } from "./types";

export enum MovementEnum {
  "Accion A" = 0,
  "Accion B" = 1,
  "" = 2,
}
export class MovementService extends AuthenticatedService {
  async registerMovements(
    shareholderId: number,
    date: string,
    typeAction: string,
    codeAction: number,
    amount: number | undefined
  ) {
    const data = {
      id: this.authStore.user?.id,
      user_id: shareholderId,
      date,
      type_action: typeAction,
      code_action: codeAction,
      amount,
    };
    const url = `${getEnvConfig().apiURL}/movements/create_movements`;

    return getResponse(url, this.token, "POST", data, undefined);
  }

  async getMovements(
    page = 0,
    account_active = 0,
    filter: object = {}
  ): Promise<ResponsePagination<ShareholderMovement[]>> {
    let url = `${getEnvConfig().apiURL}/movements/all?`;

    if (page > 0) {
      url += `page=${page}`;
    }
    if (account_active > 0) {
      url += `&account_id=${account_active}`;
    }
    url += `&only_b=1&${this.parseFilters(filter)}`;

    return getResponsePagination(url, this.token, "GET");
  }

  async downloadExcel(user_id: number, filter: object): Promise<void> {
    const url = `${
      getEnvConfig().apiURL
    }/movements/all/list/excel?account_id=${user_id}&${this.parseFilters(filter)}`;

    return fetchDownloadFile(url, "movimientos.xlsx");
  }

  async downloadContract(movementId: number) {
    const url = `${getEnvConfig().apiURL}/movements/contracts/get`;
    const body = {
      movement_id: movementId,
    };
    return fetchDownloadFile(url, "contrato.pdf", 'post', body);
  }

  parseFilters(filters: any){
    const parsedFilters = {
      start_month: "",
      start_year: "",
      end_month: "",
      end_year: "",
    };

    if (filters.start_date != "" && filters.start_date != undefined) {
      parsedFilters.start_month = filters.start_date.getMonth() + 1;
      parsedFilters.start_year = filters.start_date.getFullYear();
    }

    if (filters.end_date != "" && filters.end_date != undefined) {
      parsedFilters.end_month = filters.end_date.getMonth() + 1;
      parsedFilters.end_year = filters.end_date.getFullYear();
    }

    return new URLSearchParams(parsedFilters).toString();
  }

}
